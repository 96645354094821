const config = {
  surveyTitle: "Video Ad Test",
  videoSettingsNextRoute: "vid-creative",
  creativeUploadPevRoute: "vid-setting",
  creativeUploadNextRoute: "vid-brand-strategy",
  personaNextRoute: "vid-key-metrics",
  personaPrevRoute: "vid-creative",
  keyMetricsNextRoute: "vid-audience",
  keyMetricsPrevRoute: "vid-brand-strategy",
  audiencePrevRoute: "vid-key-metrics",
  audienceNextRoute: "vid-launch",
  launchPrevRoute: "vid-audience",
  progressBarSteps: [
    {
      title: "General Settings",
      subItems: ["General Study information"],
      icon: {
        name: "IconAdjustments",
        width: "32",
        height: "33",
        viewBox1: "32",
        viewBox2: "33",
        color: "transparent",
      },
      index: 1,
      route: "vid-setting",
    },
    {
      title: "Creative",
      subItems: ["Creative Submission Details"],
      icon: {
        name: "IconCreative",
        width: "32",
        height: "33",
        viewBox1: "22",
        viewBox2: "23",
        color: "#4318FF",
      },
      index: 2,
      route: "vid-creative",
    },
    {
      title: "Brand Strategy",
      subItems: ["Key emotional attributes"],
      icon: {
        name: "IconPie",
        width: "32",
        height: "33",
        viewBox1: "22",
        viewBox2: "23",
        color: "transparent",
      },
      index: 3,
      route: "vid-brand-strategy",
    },
    {
      title: "Key Metrics",
      subItems: ["Brand Analysis"],
      icon: {
        name: "IconMetrics",
        width: "32",
        height: "33",
        viewBox1: "32",
        viewBox2: "33",
        color: "transparent",
      },
      index: 4,
      route: "vid-key-metrics",
    },
    {
      title: "Audience",
      subItems: ["Customize Your Audience"],
      icon: {
        name: "IconAudience",
        width: "32",
        height: "33",
        viewBox1: "23",
        viewBox2: "24",
        color: "#4318FF",
      },
      index: 5,
      route: "vid-audience",
    },
    {
      title: "Launch",
      subItems: ["Summary & Confirmation"],
      icon: {
        name: "IconRocket",
        width: "32",
        height: "33",
        viewBox1: "32",
        viewBox2: "33",
        color: "transparent",
      },
      index: 6,
      route: "vid-launch",
    },
  ],
};

export default config;
