let account = JSON.parse(localStorage.getItem("studioUser"));
let clientID = localStorage.getItem("clientID");

import axios from "axios";

export function readCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) !== -1) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function setTestAccount() {
  window.user = {
    userID: "1",
    planID: "1",
    firstName: "Vivian",
    userType: "User",
    AccountID: "1",
    AccountName: "HotSpex",
  };
}

export function clearSetting() {
  deleteCookie("HS-STUDIO");
  // localStorage.removeItem("clientID");
  localStorage.removeItem("studioUser");
  localStorage.removeItem("pID");
  localStorage.removeItem("videoAuthorLang");
}

export function setWorkingProject(pID) {
  sessionStorage.setItem("workingProject", pID);
}

export function getWorkingProject() {
  if (sessionStorage.getItem("workingProject")) {
    return parseInt(sessionStorage.getItem("workingProject"), 10);
  } else {
    return 0;
  }
}

export function setSurveysApiTrigger(data) {
  localStorage.setItem("surveysApiTrigger", data);
}

export function getSurveysApiTrigger() {
  if (localStorage.getItem("surveysApiTrigger")) {
    return JSON.parse(localStorage.getItem("surveysApiTrigger"));
  }
}

// Set a Cookie
export function setCookie(cName, cValue, expDays) {
  let date = new Date();
  date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
  // document.cookie =
  //   cName + "=" + cValue + "; " + expires + "; domain=.hotspexstudio.com";
  let domainName;
  if (
    window.location.hostname === "app.hotspexlabs.com" ||
    window.location.hostname === "pg.hotspexlabs.com" ||
    window.location.hostname === "d3n7xxuq0fwznj.cloudfront.net" ||
    window.location.hostname === "d30wz03irsnsp8.cloudfront.net" ||
    window.location.hostname === "d11qiq41szmpd8.cloudfront.net"
  ) {
    domainName = "; domain=.hotspexlabs.com";
  } else {
    domainName = "; domain=.hotspexstudio.com";
  }
  document.cookie = cName + "=" + cValue + "; " + expires + domainName;
}

export function deleteCookie(cName) {
  let domainName;
  if (
    window.location.hostname === "app.hotspexlabs.com" ||
    window.location.hostname === "pg.hotspexlabs.com" ||
    window.location.hostname === "d3n7xxuq0fwznj.cloudfront.net" ||
    window.location.hostname === "d30wz03irsnsp8.cloudfront.net" ||
    window.location.hostname === "d11qiq41szmpd8.cloudfront.net"
  ) {
    domainName =
      "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.hotspexlabs.com";
  } else {
    domainName =
      "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.hotspexstudio.com";
  }
  document.cookie = cName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  document.cookie = cName + domainName;
}

export function getCurClient() {
  // account = JSON.parse(localStorage.getItem("studioUser"));

  account =
    JSON.parse(localStorage.getItem("studioUser")) ||
    JSON.parse(readCookie("HS-STUDIO"));

  clientID = localStorage.getItem("clientID");

  const clients = account.Clients;

  let client;
  if (clientID) {
    let clientInfo = clients.find((client) => {
      return client.ClientId === parseInt(clientID, 10);
    });
    client = clientInfo !== undefined ? clientInfo : clients[0];
  } else {
    client = clients[0];
    localStorage.setItem("clientID", client.ClientId);
    localStorage.setItem("oldClientID", client.ClientId);
  }

  window.ClientId = client.ClientId;
  window.userID = account.userID;

  return client;
}

export function isEmptyObj(obj) {
  return Object.keys(obj).length === 0;
}

export function loadLangObj(storageLang, langFile) {
  axios.get(langFile, {}).then((response) => {
    localStorage.setItem(storageLang, JSON.stringify(response.data));
  });
}

export function getLangObj(storageLang, langFile) {
  if (localStorage.getItem(storageLang)) {
    return JSON.parse(localStorage.getItem(storageLang));
  } else {
    loadLangObj(storageLang, langFile);
  }
}

export function setEditableSurvey(data) {
  sessionStorage.setItem("editableSurvey", JSON.stringify(data));
}

export function getEditableSurvey() {
  if (sessionStorage.getItem("editableSurvey")) {
    return JSON.parse(sessionStorage.getItem("editableSurvey"));
  }
}

export function clearEditableSurvey() {
  sessionStorage.removeItem("editableSurvey");
}

export function setSurveySummaryData(data) {
  sessionStorage.setItem("surveySummaryData", JSON.stringify(data));
}

export function getSurveySummaryData() {
  if (sessionStorage.getItem("surveySummaryData")) {
    return JSON.parse(sessionStorage.getItem("surveySummaryData"));
  }
}

export function clearSurveySummaryData() {
  sessionStorage.removeItem("surveySummaryData");
}

export function getAccountPaymentType() {
  const data =
    JSON.parse(localStorage.getItem("studioUser")) ||
    JSON.parse(readCookie("HS-STUDIO"));
  return data.AccountPaymentArrangement;
}

export function setSurveyPrice(data) {
  sessionStorage.setItem("surveyPrice", JSON.stringify(data));
}

export function getSurveyPrice() {
  if (sessionStorage.getItem("surveyPrice")) {
    return JSON.parse(sessionStorage.getItem("surveyPrice"));
  }
}

export function setPackTestPrice(data) {
  sessionStorage.setItem("packTestPrice", JSON.stringify(data));
}

export function getPackTestPrice() {
  if (sessionStorage.getItem("packTestPrice")) {
    return JSON.parse(sessionStorage.getItem("packTestPrice"));
  }
}

export function clearSurveyPrice() {
  sessionStorage.removeItem("surveyPrice");
}

export function clearPackTestPrice() {
  sessionStorage.removeItem("packTestPrice");
}

export function setSurveyTypeId(data) {
  sessionStorage.setItem("surveyTypeId", JSON.stringify(data));
}

export function getSurveyTypeId() {
  if (sessionStorage.getItem("surveyTypeId")) {
    return JSON.parse(sessionStorage.getItem("surveyTypeId"));
  }
}

export function setSurveyUnEncryptId(data) {
  sessionStorage.setItem("surveyUnEncryptId", JSON.stringify(data));
}

export function getSurveyUnEncryptId() {
  if (sessionStorage.getItem("surveyUnEncryptId")) {
    return JSON.parse(sessionStorage.getItem("surveyUnEncryptId"));
  }
}

export function getUserInfo() {
  return (
    JSON.parse(localStorage.getItem("studioUser")) ||
    JSON.parse(readCookie("HS-STUDIO"))
  );
}

export function setRunSurveyMode(data) {
  sessionStorage.setItem("runSurveyMode", data);
}

export function setFeasibilitySuccess(data) {
  sessionStorage.setItem("feasibilitySuccess", data);
}

export function getRunSurveyMode() {
  if (sessionStorage.getItem("runSurveyMode")) {
    return JSON.parse(sessionStorage.getItem("runSurveyMode"));
  }
}

export function getFeasibilitySuccess() {
  if (sessionStorage.getItem("feasibilitySuccess")) {
    return JSON.parse(sessionStorage.getItem("feasibilitySuccess"));
  }
}

export function getToRouterPath() {
  if (localStorage.getItem("toRouterPath")) {
    return localStorage.getItem("toRouterPath");
  }
}

export function getSiteType() {
  if (localStorage.getItem("siteType")) {
    return localStorage.getItem("siteType");
  }
}

export function setDashboardURL(data) {
  localStorage.setItem("dashboardURL", data);
}

export function getDashboardURL() {
  if (localStorage.getItem("dashboardURL")) {
    return localStorage.getItem("dashboardURL");
  }
}

export function getLocalAccessToken() {
  const accessToken = JSON.parse(localStorage.getItem("studioUser"));
  return accessToken?.AccessToken;
}

export function getLocalRefreshToken() {
  const refreshToken = JSON.parse(localStorage.getItem("studioUser"));
  return refreshToken?.RefreshToken;
}

export function updateLocalAccessToken(token) {
  let user = JSON.parse(localStorage.getItem("studioUser"));
  if (user) {
    user.AccessToken = token;
    localStorage.setItem("studioUser", JSON.stringify(user));
  }

  let cookieUser = JSON.parse(readCookie("HS-STUDIO"));
  if (cookieUser) {
    cookieUser.AccessToken = token;
    setCookie("HS-STUDIO", JSON.stringify(cookieUser));
  }
}
