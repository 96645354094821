const config = {
  surveyTitle: "UA Video Ad Test",
  videoSettingsNextRoute: "vid-ua-creative",
  creativeUploadPevRoute: "vid-ua-setting",
  creativeUploadNextRoute: "vid-ua-brand-strategy",
  personaNextRoute: "vid-ua-customize",
  personaPrevRoute: "vid-ua-creative",
  keyMetricsNextRoute: "vid-ua-launch",
  keyMetricsPrevRoute: "vid-ua-brand-strategy",
  launchPrevRoute: "vid-ua-customize",
  progressBarSteps: [
    {
      title: "General Settings",
      icon: "/images/icons/config.svg",
      index: 1,
      route: "vid-ua-setting",
    },
    {
      title: "Creative",
      icon: "/images/icons/video2.svg",
      index: 2,
      route: "vid-ua-creative",
    },
    {
      title: "Brand Strategy",
      icon: "/images/icons/video3.svg",
      index: 3,
      route: "vid-ua-brand-strategy",
    },
    {
      title: "Customize",
      icon: "/images/icons/video4.svg",
      index: 4,
      route: "vid-ua-customize",
    },
    {
      title: "Launch",
      icon: "/images/icons/launch.svg",
      index: 6,
      route: "vid-ua-launch",
    },
  ],
};

export default config;
